@import url('https://fonts.googleapis.com/css2?family=Itim&family=Poppins&family=Roboto+Mono&display=swap');

.main__container{
    background-image: url('../assets/Group\ 2@3x_bg\ \(convert.io\).webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.main__title{
    font-size: 1.5rem;
    width: 95%;
    color: #000;
    text-align: center;
    padding-top: 3rem;
    font-family: 'Itim', cursive;
    cursor: default;
    display: flex;
    justify-content: space-between;
}

.Rlogo{
    width: 20%;
    z-index: 2;
    margin-top: -3rem;

}

.kiitlogo, .elabslogo{
    width: 8%;
    height: auto;
}

.kiitlogo{
    margin-top: 1rem;
    height: 6rem;
}

.main__form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
    height: min-content;
    background-color: #fff;
    margin-top: 0rem;
    box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.2);
    padding: 1rem;
    border-radius: 1.5rem;
    outline: none;
}

.mainsubform__title{
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    text-align: center;
    padding: 1rem;
    width: 100%;
    border-radius: 1.5rem 1.5rem 0 0;
    cursor: default;
}
.mainform__input{
    display: flex;
    width: 60%;
    justify-content: space-between;
    margin: 1rem 0;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.mainform__input__details{
    padding: 0.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border-radius: 0.5rem;
    margin: 0.2rem;
}

.player__container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    border-radius: 0.5rem;
}

.mainform__input label{
    display: block;
}

.mainform__submit{
    width: 90%;
    height: 4rem;
    margin: 1rem 0;
    padding: 0.5rem;
    border: none;
    border-radius: 0.5rem;
    outline: none;
    display: flex;
    justify-content: space-between;
    /* background-color: #eba347; */
    color: #fff;
}

.mainform__submit h1{
    color: white;
    cursor: pointer;
}

.mainform__submit__button{
    border: none;
    outline: none;
    background-color: #eba347;
    padding: 0 2rem;
    border-radius: 20px;
    color: #000;
    font-size: 1.5rem;
    font-weight: 900;
    font-display: block;
    cursor: pointer;
}

input{
    border: 1px solid black
}
form {
    width: 100vw;
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
    align-items: center;
}

label {
    font-weight: 700;
}
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: hsl(34, 100%, 62%);
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: hsl(34, 100%, 40%);
}

@media(max-width: 700px){

    .elabslogo, .kiitlogo{
        display: none;
    }
    .Rlogo{
        margin: 0 auto;
        margin-bottom: 2rem;
        width: 20rem;

    }
    .main__form{
        margin-top: 0;
        width: 90vw;
    }
    .player__container{
        flex-direction: column;
    }
    .mainform__input{
        flex-direction: column;
        width: 100%
    }
    .mainform__submit{
        position: relative;
    }
    .mainform__submit__button{
        position: absolute;
        bottom: 2rem;
        font-size: 1.5rem;
        padding: 0 2rem;
        margin: 0 15vw;
    }
    .mainform__submit h1{
        position: absolute;
        bottom: -0.2rem;
        font-size: 1.5rem;
        font-weight: 900;
        color: #fff;
    }
}