.subform__container{
    display: flex;
    flex-direction: column;
    width: 20vw;
    margin: 0.5rem;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.2);
}

.subform__title{
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    text-align: center;
    padding: 1rem;
    width: 100%;
    border-radius: 0.5rem 0.5rem 0 0;
}

/* .form__input{
    width: 100%;
    height: 2rem;
    margin: 1rem 0;
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: 700;
    border: none;
    border-radius: 0.5rem;
    outline: none;
} */

label{
    display: none;
}

.form__input__details{
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border: 1px solid #000;
    border-radius: 0.5rem;
    margin: 0.2rem;
}

.form__input__dropdown{
    width: 100%;
    height: 0.5rem;
    /* margin: 1rem 0; */
    /* padding: 0.5rem; */
    font-size: 1rem;
    border-radius: 0.5rem;
}

.form__dropdown{
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border: 1px solid #000;
    border-radius: 0.5rem;
    margin: 0.4rem;
}

.form__dropdown__option{
    width: 100%;
    height: 0.5rem;
    /* margin: 1rem 0; */
    /* padding: 0.5rem; */
    font-size: 1rem;
    border-radius: 0.5rem;
}

p{
    color: red;
    font-size: smaller;
    font-size: 0.7rem;
}

@media(max-width: 800px){
    .subform__container{
        width: 100%;
    }
    
}