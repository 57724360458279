.contact__modal{
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    /* z-index: 100; */
}

a{
    text-decoration: none;
    color: #000;
    padding: 5px;
}

h3{
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
}
.mail__container{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    overflow: auto;
    color: hsl(13, 100%, 31%);
}

@media(max-width: 768px){
    .mail__container{
        align-items: center;
    }
    .contact__modal h1{
        font-size: 1.3rem;
        font-weight: 300;
    }
    .contact__modal h3{
        font-size: 1.1rem;
        font-weight: 300;
    }
    .mail__container a{
        font-size: 1.1rem;
    }
}